<template>
  <v-container fluid tag="section">
    <base-material-card
      color="#7733FF"
      icon="mdi-clipboard-text"
      inline
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h5">
          <b>{{ $t("materials") }}</b>
        </h1>
      </template>
      <v-btn
        color="primary"
        rounded
        absolute
        fab
        top
        right
        @click="handleOpenAddDialog"
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
      <v-select
        background-color="#f5f5f5"
        filled
        :items="languageOptions"
        v-model="selectedLanguage"
        item-text="text"
        item-value="value"
        style="width: 92px"
        class="language-options-select"
        @change="handleSelectLanguage"
      >
      </v-select>
      <div class="items-center" v-if="tableFilesLoading">
        <v-progress-circular
          indeterminate
          size="60"
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-tabs v-model="tabs" @change="handleChangeTab">
          <v-tab>{{ $t("news_letter") }}</v-tab>
          <v-tab>{{ $t("dealboard_libray") }}</v-tab>
        </v-tabs>

        <v-simple-table>
          <thead>
            <tr>
              <th>{{ $t("file") }}</th>
              <th class="text-center">
                {{ $t("download") }}
              </th>
              <th class="text-center">
                {{ $t("edit") }}
              </th>
              <th class="text-center">
                {{ $t("remove") }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(file, index) in files" :key="index">
              <td>{{ file.name }}</td>
              <td class="text-center">
                <v-btn
                  icon
                  :download="file.name"
                  class="px-2 ml-1 btnDownloadFile"
                  :id="file.id"
                  :name="file.name"
                  color="primary"
                  @click="handleDownloadFile(file)"
                  min-width="0"
                  :loading="fileDownloading === file.id"
                  small
                >
                  <v-icon small>mdi-download</v-icon>
                </v-btn>
              </td>
              <td class="text-center">
                <v-btn
                  class="px-2 ml-1 secondary btn-download"
                  min-width="0"
                  small
                  @click="handleEditFile(file)"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </td>
              <td class="text-center">
                <v-btn
                  class="px-2 ml-1"
                  color="red"
                  min-width="0"
                  small
                  @click="handleOpenDialogDeleteFile(file)"
                >
                  <v-icon small>mdi-trash-can</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </base-material-card>
    <v-dialog v-if="dialog" v-model="dialog" scrollable>
      <AddOrEditFileDialog
        :files="files"
        :fileToEdit="fileToEdit"
        :editFile="editFile"
        :currentTab="tabs"
        :currentLanguage="selectedLanguage"
        @successFileRequest="(e) => handleSuccessFileRequest(e)"
        @closeDialog="handleCloseDialog"
      />
    </v-dialog>
    <v-dialog v-if="deleteDialog" v-model="deleteDialog" scrollable>
      <DeleteConfirmationModal
        :obj="deleteObj"
        :name="deleteObj.name"
        @close="deleteDialog = false"
        @delete="deleteFile"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
</template>

<script>
import { LanguageTextEnum, LanguageEnum } from "@/shared/enums/LanguageEnum";
import { B2bMaterialFileTypeEnum } from "@/shared/enums/B2bMaterialFileEnum";
import AddOrEditFileDialog from "./AddOrEditFileDialog.vue";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import ApiService from "@/services/ApiService";

export default {
  name: "MaterialsB2BFilesComponent",
  components: {
    AddOrEditFileDialog,
    DeleteConfirmationModal,
  },
  data() {
    return {
      dialog: false,
      apiService: new ApiService(),
      selectedLanguage: LanguageEnum.pt,
      editFile: false,
      fileToEdit: null,
      deleteDialog: false,
      deleteObj: null,
      files: [],
      fileDownloading: null,
      tabs: 0,
      B2bMaterialFileTypeEnum,
      literalFileType: {
        [0]: B2bMaterialFileTypeEnum.Newsletters,
        [1]: B2bMaterialFileTypeEnum.Dealboard_Library,
      },
      languageOptions: [
        {
          text: "PT",
          value: LanguageEnum.pt,
        },
        {
          text: "EN",
          value: LanguageEnum.en,
        },
      ],
      tableFilesLoading: false,
    };
  },
  async created() {
    await this.getFiles(this.B2bMaterialFileTypeEnum.Newsletters);
  },
  methods: {
    async handleGetFiles() {
      const fileType = this.literalFileType[this.tabs];

      if (fileType) {
        await this.getFiles(fileType);
      }
    },
    async handleChangeTab() {
      await this.handleGetFiles();
    },
    async getFiles(fileType, language = null) {
      this.tableFilesLoading = true;
      const selectedFileType = fileType
        ? fileType
        : this.literalFileType[this.tabs];
      const selectedLanguage = language ? language : this.selectedLanguage;

      await this.apiService
        .getRequest(
          `b2bmaterials/files/${selectedLanguage}/${selectedFileType}`
        )
        .then((resp) => {
          this.files = resp.content;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });

      this.tableFilesLoading = false;
    },
    async handleSelectLanguage() {
      await this.handleGetFiles();
    },
    handleEditFile(file) {
      this.editFile = true;
      this.fileToEdit = file;
      this.dialog = true;
    },
    handleOpenAddDialog() {
      this.editFile = false;
      this.fileToEdit = null;
      this.dialog = true;
    },
    handleCloseDialog() {
      this.dialog = false;
      this.editFile = false;
      this.fileToEdit = null;
    },
    async deleteFile(file) {
      await this.apiService
        .deleteRequest(`b2bmaterials/files/${file.id}`)
        .then(async (resp) => {
          this.deleteDialog = false;
          this.$toast.success(this.$t("saved"));
          await this.getFiles();
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    handleOpenDialogDeleteFile(file) {
      this.deleteObj = file;
      this.deleteDialog = true;
    },
    async handleSuccessFileRequest(e) {
      // colocando o tab e idioma de acordo com o tipo de arquivo e idioma que ele salvou
      this.dialog = false;
      // -1 porque o tab começa em 0
      this.tabs = e.type - 1;
      this.selectedLanguage = e.language;
      await this.getFiles(e.type, e.language);
    },
    async handleDownloadFile(file) {
      this.fileDownloading = file.id;

      await this.apiService
        .postRequest(`b2bmaterials/files/${file.id}/download`)
        .then((resp) => {
          resp = "data:application/octet-stream;base64," + resp.message;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", file.name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });

      this.fileDownloading = null;
    },
  },
};
</script>

<style scoped>
.language-options-select {
  position: absolute;
  right: 120px;
  top: -30px;
}

.items-center {
  display: grid;
  place-items: center;
}
</style>
